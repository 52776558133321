import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'
import useHelpData from '../../hooks/useHelpData'
import CommonH3 from '../atoms/commonH3'
import BreakPoints from '../../styles/breakPoints'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  padding: 25px 30px;
  width: 500px;
  min-height: 276px;
  margin: 15px;
  float: left;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  h3 {
    span {
      font-size: 1.6em;
      border: none;

      &:before {
        display: none;
      }
    }
  }

  .category-list {
    li {
      margin: 0 0 5px 15px;
      position: relative;

      &:before {
        display: block;
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 7px;
        left: -14px;
        border-left: 8px solid #3ec7b3;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
      }

      a {
        max-width: 100%;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
        display: inline-block;
      }
    }
  }

  .all-link {
    display: block;
    width: fit-content;
    margin: 0 0 0 auto;
    text-align: right;
    color: #00b5a6;
  }

  @media screen and (max-width: 1074px) {
    margin: 15px auto;
    width: 80vw;
    float: unset;
    min-height: unset;
  }

  ${BreakPoints.large} {
    width: calc(100vw - 45px);
  }

  ${BreakPoints.medium} {
    width: calc(100vw - 20px);
    padding: 15px 15px 15px 5px;
  }
}
`
type IProps = {
  /** カテゴリタイトル */
  categoryTitle: string
  /** URL */
  slug: string
  /** カテゴリID */
  helpCategoryId: string
  /** ページタイトル */
  limit: number
  /** すべての記事を表示フラグ */
  visibleAllLink?: boolean
}

/**
 * カテゴリカード
 * @constructor
 */
const CategoryCard = ({
  categoryTitle,
  slug,
  helpCategoryId,
  limit,
  visibleAllLink,
}: IProps) => {
  const { helpDetails } = useHelpData()
  const fixDetails = helpDetails.filter(
    (val) => val.helpCategoryId.split(',').indexOf(helpCategoryId) !== -1
  )
  const morePage = limit < fixDetails.length
  return (
    <Wrapper className="category-card">
      <CommonH3 label={categoryTitle} className="category-title" />
      <ul className="category-list">
        {fixDetails.splice(0, limit).map((item) => (
          <li key={`article-${item.helpId}`}>
            <Link to={useRouteParam(`/help/article/${item.helpId}`)}>
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
      {visibleAllLink && morePage && (
        <Link to={useRouteParam(`/help/${slug}`)} className="all-link">
          <span itemProp="headline">すべての記事を表示</span>
        </Link>
      )}
    </Wrapper>
  )
}

export default CategoryCard
